export const hamburgerStyle = {
	fontSize: "1.5em",
	margin: "0 10px",
	color: "#fff",
	fontStyle: "normal",
	cursor: "pointer",
};

export const iconStyle = {
	fontSize: "1.5em",
	margin: "0 7px",
	cursor: "pointer",
};
