import React from "react";
import "./lastUsedProcess.css";

export const LastUsedProcess = ({ element, onClickLastUsedProcess }) => {
	const { nombre, fuente_icono } = element;
	return (
		<div className="lastUsed--main--container">
			<div
				className="lastUsed--main--container--data"
				onClick={onClickLastUsedProcess}
			>
				<i className={fuente_icono || `fas fa-list`} />
				<div>{nombre}</div>
			</div>
		</div>
	);
};
