import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { Base64 } from "js-base64";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { getUrl } from "../../actions/getUrl";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "./recoverPassword.css";
import { FormTitle } from "../../components/formTitle/formTitle";

export const RecoverPassword = () => {
	const history = useHistory();

	const [userData, setUserData] = useState(null);

	const defaultValues = { email: "" };

	const {
		control,
		formState: { errors },
		handleSubmit,
	} = useForm({ defaultValues });

	const getFormErrorMessage = (name) => {
		return (
			errors[name] && <small className="p-error">{errors[name].message}</small>
		);
	};

	const header = <div className="alchemy--logo--container" />;

	const onSubmit = async () => {
		const { userId, connection, domain } = userData;
		const myParams = `?connection=${connection}&userid=${userId}`;
		const uri = await getUrl(domain, "recoverPassword", myParams);
		const myHeaders = new Headers();
		myHeaders.append("Accept", "*/*");
		myHeaders.append("Content-Type", `application/json`);
		try {
			const res = await fetch(uri, {
				headers: myHeaders,
				method: "POST",
			});
			if (res.status === 200) {
				const response = await res.json();
				//
			} else {
				console.log("ERORR recoverPassword status code");
			}
		} catch (error) {
			console.log("ERORR recoverPassword: ", error);
		}
	};

	useEffect(() => {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const sessionParam = urlParams.get("session");
		const decoded = Base64.decode(sessionParam);
		const sessionData = JSON.parse(decoded);
		const { user, connection } = sessionData;
		const myConnection = connection.connection;
		const url_be_menu = connection.url_be_menu;
		setUserData({
			userId: user,
			connection: myConnection,
			domain: url_be_menu,
		});
	}, []);

	return (
		<div className="register--screen--main--container">
			<Card header={header} style={{ width: "400px", zIndex: "100" }}>
				<FormTitle title="Olvidó su contraseña ?" />
				<form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
					<div
						className="p-field p-col-12 p-md-6"
						style={{ margin: "28px 10px" }}
					>
						<span className="p-float-label p-input-icon-right">
							<i className="far fa-envelope" />
							<Controller
								name="email"
								control={control}
								rules={{
									required: "Email is required.",
									pattern: {
										value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
										message: "Invalid email address. E.g. example@email.com",
									},
								}}
								render={({ field, fieldState }) => (
									<InputText
										id={field.name}
										{...field}
										className={classNames({ "p-invalid": fieldState.invalid })}
									/>
								)}
							/>
							<label
								htmlFor="email"
								className={classNames({ "p-error": !!errors.email })}
							>
								Ingrese su usuario o correo electrónico
							</label>
						</span>
						{getFormErrorMessage("email")}
					</div>

					<div
						onClick={() => history.goBack()}
						className="recover--screen--main--container--inputs--link"
					>
						Volver
					</div>

					<div
						className="p-field p-col-12 p-md-6"
						style={{ margin: "28px 10px" }}
					>
						<Button
							disabled={false}
							type="submit"
							label="Ingresar"
							className="p-button-info p-button-raised"
						/>
					</div>
				</form>
			</Card>
		</div>
	);
};
