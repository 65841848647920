/* eslint-disable no-duplicate-case */
import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";

export const NotificationTable = ({ jsonData }) => {
	const [notificationsData, setNotificationsData] = useState([]);
	const [expandedRows, setExpandedRows] = useState(null);

	useEffect(() => {
		setNotificationsData(jsonData);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const formatCurrency = (value) => {
		return value.toLocaleString("en-US", {
			style: "currency",
			currency: "USD",
		});
	};

	const formatDate = (value) => {
		return value.toLocaleDateString("en-US");
	};

	const onClicRow = (element, data) => {
		const { proceso, tipo_proceso, id, nombre } = data;
		const myData = {
			...element,
			proceso,
			tipo_proceso,
			processId: id,
			nombre,
		};
		return <Button icon="pi pi-search" onClick={() => console.log(myData)} />;
	};

	const styleColumn = (rowData, column) => {
		const { name, type } = column;
		switch (type) {
			case "float":
				return formatCurrency(rowData[name]);
			case "integer":
				return rowData[name];
			case "date":
				const myDate = new Date(rowData[name]);
				return formatDate(myDate);
			case "hide": //agrego para ocultar mp 30/09/2021
				return "";
			default:
				return rowData[name];
		}
	};

	const dynamicColumns = (columns) => {
		return columns.map((column) => (
			<Column
				key={column.name}
				field={column.name}
				header={column.header}
				body={(row) => styleColumn(row, column)}
				sortable
			></Column>
		));
	};

	const rowExpansionTemplate = (data) => {
		const columns = data.columns;
		return (
			<div className="orders-subtable">
				<h5>Pendientes</h5>
				<DataTable value={data.pendientes}>
					{dynamicColumns(columns)}
					<Column
						headerStyle={{ width: "4rem" }}
						body={(elem) => onClicRow(elem, data)}
					></Column>
				</DataTable>
			</div>
		);
	};

	//agrego style={{ display: "none" }} en id para ocultar mp 30/09/2021
	return (
		<div className="datatable-rowexpansion-demo">
			<div className="card">
				<DataTable
					value={notificationsData}
					expandedRows={expandedRows}
					onRowToggle={(e) => setExpandedRows(e.data)}
					rowExpansionTemplate={rowExpansionTemplate}
					dataKey="id"
				>
					<Column expander style={{ width: "3em" }} />
					<Column field="id" header="id" style={{ display: "none" }}></Column>
					<Column field="nombre" header="Proceso"></Column>
					<Column field="cantidad" header="Cantidad"></Column>
				</DataTable>
			</div>
		</div>
	);
};
