export const setHeadersToken = (method = "GET", body = null) => {
	const token = localStorage.getItem("token");
	const myHeaders = new Headers();
	myHeaders.append("Authorization", `Bearer ${token}`);
	myHeaders.append("Accept", "*/*");
	myHeaders.append("Content-Type", `application/json`);
	if (body) {
		return {
			method,
			headers: myHeaders,
			redirect: "follow",
			body,
		};
	} else {
		return {
			method,
			headers: myHeaders,
			redirect: "follow",
		};
	}
};
