import React from "react";
import byTechGroup from "../../assets/icons/by--group--tech.png";
import "./footer.css";

export const Footer = () => {
	return (
		<div className="footer--main--container">
			<a target="_blank" href="https://www.grupo-tech.com">
				<div className="footer--main--container--image">
					<img src={byTechGroup} alt="..." />
				</div>
			</a>
		</div>
	);
};
