import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { App } from "./App";
import { store } from "./store/store";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import global_es from "./translations/es/global.json";
import global_en from "./translations/en/global.json";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./index.css";

i18next.init({
	interpolation: { escapeValue: false },
	lng: "es",
	resources: {
		es: {
			global: global_es,
		},
		en: {
			global: global_en,
		},
	},
});

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<I18nextProvider i18n={i18next}>
				<App />
			</I18nextProvider>
		</Provider>
	</React.StrictMode>,
	document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
