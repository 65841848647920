import React from "react";

export const HeaderIcon = ({ element, iconStyle }) => {
	return (
		<i
			onClick={element.onClickIcon}
			className={element.icon}
			style={iconStyle}
			title="Menu"
		/>
	);
};
