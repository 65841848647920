import jwt_decode from "jwt-decode";
import { getUrl, getDomain } from "../../actions/getUrl";
import { setHeadersToken } from "../../api/api";

export const saveLastUsed = async () => {
	try {
		const session = sessionStorage.getItem("alchemy_last_used");
		const lastused = JSON.parse(session);
		const token = localStorage.getItem("token");
		const { user, conn } = jwt_decode(token); //cambio id/db por user/conn 01/03/2022
		const body = {
			connection: conn,
			userid: user,
			lastused,
		};
		const bodyStr = JSON.stringify(body);
		const myHeaders = setHeadersToken("POST", bodyStr);
		const userUrls = JSON.parse(localStorage.getItem("user-data-url"));
		//const domain = await getDomain("domain"); //agregado para obtener de config mp 12/11/2021
		const uri = await getUrl(userUrls.url_be_menu, "saveLastUsed"); //cambio mp 29/11/2021
		//const uri = await getUrl(domain, "saveLastUsed");//comento mp 29/11/2021
		const res = await fetch(uri, myHeaders);
		if (res.status == 200) {//agregado mp 15/05/23
			const response = await res.json();//agregado mp 15/05/23
			if (response.error == "0") {//agregado mp 15/05/23
				localStorage.setItem("resUnload", res);
			}
		}
		//const response = await res.json();
	} catch (error) {
		console.log("ERROR saveLastUsed: ", error);
	}
};
