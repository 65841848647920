import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Messages } from "primereact/messages";
import { getUrl, getDomain } from "../../actions/getUrl";
import jwt_decode from "jwt-decode";
import profileImg from "../../assets/icons/profile.png";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { Password } from "primereact/password";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { useForm, Controller } from "react-hook-form";
import "./profileForm.css";

export const ProfileForm = () => {
	const { t, i18n } = useTranslation("global");
	const msgs1 = useRef(null);
	const [openResetPassword, setOpenResetPassword] = useState(false);
	const [imageBase64, setImageBase64] = useState(null);
	const [fileName, setFileName] = useState(null);
	const user = JSON.parse(localStorage.getItem("user-session")) || {};
	//const base = localStorage.getItem("connection"); //cambio para obtener del token 16/11/2021
	const token = localStorage.getItem("token");
	const { conn, userid } = jwt_decode(token); //cambio id/db user/conn 01/03/2022

	const [profile, setProfile] = useState({
		src: user.img_profile ? user.img_profile : profileImg,
		errored: false,
	});

	const onErrorProfile = () => {
		if (!profile.errored) {
			setProfile({
				src: profileImg,
				errored: true,
			});
		}
	};

	const defaultValues = {
		nameUser: user.username || "",
		email: user.email || "",
		password: "",
		newPassword: "",
		confirmNewPassword: "",
	};

	const {
		control,
		formState: { errors },
		handleSubmit,
	} = useForm({ defaultValues });

	const getFormErrorMessage = (name) => {
		return (
			errors[name] && <small className="p-error">{errors[name].message}</small>
		);
	};

	const passwordHeader = <h6>Pick a password</h6>;

	const passwordFooter = (
		<React.Fragment>
			<Divider />
			<p className="p-mt-2">Suggestions</p>
			<ul className="p-pl-2 p-ml-2 p-mt-0" style={{ lineHeight: "1.5" }}>
				<li>At least one lowercase</li>
				<li>At least one uppercase</li>
				<li>At least one numeric</li>
				<li>Minimum 8 characters</li>
			</ul>
		</React.Fragment>
	);

	const onSubmitProfileData = async (data, headers) => {
		const formData = {
			connection: conn,
			userid: userid,
			username: data.nameUser,
			email: data.email,
			oldPass: data.password,
			newPass: data.newPassword,
		};

		const urlDomain = JSON.parse(localStorage.getItem("user-data-url"));
		const domain = urlDomain.url_be_menu; //cambiado mp 29/11/2021
		//const domain = await getDomain("domain");//cambio para obtener de routes mp 30/09/2021
		const url = await getUrl(domain, "saveUser");
		try {
			const response = await fetch(url, {
				method: "POST",
				body: JSON.stringify(formData),
				headers,
			});
			if (response.status === 200) {
				return true;
			}
		} catch (error) {
			console.log("onSubmitProfileData - ERROR: ", error);
		}
		return false;
	};

	const onSubmitProfileAvatar = async (data, headers) => {
		let formData = new FormData();
		formData.append("imgProfile", profile.src);
		let index = imageBase64.indexOf(",");
		index++;
		const base64textString = imageBase64.slice(index, imageBase64.length);
		const archivo = {
			nombre: data.name,
			nombreArchivo: fileName,
			base64textString,
			connection: conn,
			userid: userid,
		};
		const urlDomain = JSON.parse(localStorage.getItem("user-data-url"));
		const domain = urlDomain.url_be_menu;//cambio mp 29/11/2021
		//const domain = await getDomain("domain");//cambio para obtener de routes mp 30/09/2021
		const url = await getUrl(domain, "saveImageProfile");

		try {
			const response = await fetch(url, {
				method: "POST",
				headers,
				body: JSON.stringify(archivo),
			});
			if (response.status === 200) {
				const userSession = JSON.parse(localStorage.getItem("user-session"));
				userSession.picture = imageBase64;
				localStorage.setItem("user-session", JSON.stringify(userSession));
				const element = document.getElementById("navbar-user-picture-element");
				element.src = imageBase64;
				return true;
			}
		} catch (error) {
			console.log("onSubmitProfileAvatar - ERROR: ", error);
		}
		return false;
	};

	const onSubmit = async (data) => {
		let newData = { ...data, name: user.username };
		const myToken = localStorage.getItem("token");
		const headers = {
			Authorization: `Bearer ${myToken}`,
			"Access-Control-Allow-Credentials": true,
			"Content-Type": "application/json",
		};

		const statusProfile = await onSubmitProfileData(data, headers);

		let statusAvatar = null;
		if (imageBase64 != null) {
			statusAvatar = await onSubmitProfileAvatar(newData, headers);
		}

		if (statusProfile == true && statusAvatar == null) {
			// el perfil fue almacenados correctamente
			addMessages("success", "", t("profile.errors.operationSuccess"));
		} else {
			if (statusProfile == false && statusAvatar == null) {
				// No se pudo almacenar el perfil
				addMessages("error", "", t("profile.errors.operationFailure"));
			} else {
				if (statusProfile == true && statusAvatar == true) {
					// los datos del perfil y la imagen fueron almacenados correctamente
					addMessages("success", "", t("profile.errors.operationFullSuccess"));
				} else {
					if (statusProfile == true && statusAvatar == false) {
						// no se pudo almacenar la imagen de perfil
						addMessages("error", "", t("profile.errors.operationImageFailure"));
					} else {
						// No se pudo almacenar el perfil y el avatar
						addMessages("error", "", t("profile.errors.operationFullFailure"));
					}
				}
			}
		}
	};

	const addMessages = (sev, summ, det) => {
		msgs1.current.show([
			{
				severity: sev,
				summary: summ,
				detail: det,
				sticky: null,
				life: 2500,
			},
		]);
	};

	const openResetPasswordFunction = () => setOpenResetPassword((x) => !x);

	function getBase64(file) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});
	}

	const handleChangeAdd = async (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const file = e.target.files[0];
			const fileSource = URL.createObjectURL(file);
			setProfile({ ...profile, src: fileSource });

			getBase64(file)
				.then((response) => {
					setImageBase64(response);
					setFileName(file.name);
				})
				.catch((error) => {
					console.log("ERROR B64", error);
					setImageBase64(null);
					setFileName(null);
				});
		}
	};

	useEffect(() => {
		const user = JSON.parse(localStorage.getItem("user-session"));
		const profile = user.picture;

		setProfile({
			src: profile || profileImg,
			errored: false,
		});
	}, []);

	return (
		<div style={{ width: "400px" }}>
			<div className="upload--files--add--main--container">
				<div className="upload--files--add--main--container--frame">
					<input
						type="file"
						className="upload--files--add--main--container--frame--input"
						onChange={handleChangeAdd}
					/>
					<div className="upload--files--add--main--container--mask">
						<img
							id="user-picture-element"
							src={profile.src || profileImg}
							alt="..."
							className="upload--files--add--main--container--mask--image"
							onError={onErrorProfile}
						/>
					</div>
				</div>
				<div className="upload--files--add--main--container--username">
					<div className="upload--files--add--main--container--username--title">
						{t("profile.title")}
					</div>
					<div className="upload--files--add--main--container--username--text">
						{userid}
					</div>
				</div>
			</div>

			<form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
				<div
					className="p-field p-col-12 p-md-6"
					style={{ margin: "28px 10px" }}
				>
					<span className="p-float-label p-input-icon-right">
						<i className="pi pi-users" />
						<Controller
							name="nameUser"
							control={control}
							rules={{ required: t("profile.validations.name") }}
							render={({ field, fieldState }) => (
								<InputText
									disabled={true}
									id={field.nameUser}
									{...field}
									autoFocus
									className={classNames({
										"p-invalid": fieldState.invalid,
									})}
								/>
							)}
						/>
						<label
							htmlFor="nameUser"
							className={classNames({ "p-error": errors.nameUser })}
						>
							{t("profile.fullname")}
						</label>
					</span>
					{getFormErrorMessage("nameUser")}
				</div>

				<div
					className="p-field p-col-12 p-md-6"
					style={{ margin: "28px 10px" }}
				>
					<span className="p-float-label p-input-icon-right">
						<i className="pi pi-envelope" />
						<Controller
							name="email"
							control={control}
							rules={{
								required: t("profile.validations.email"),
								pattern: {
									value: /[A-Za-z0-9._%+-]$/i,
									message: t("profile.validations.email_format"),
								},
							}}
							render={({ field, fieldState }) => (
								<InputText
									id={field.email}
									{...field}
									className={classNames({
										"p-invalid": fieldState.invalid,
									})}
								/>
							)}
						/>
						<label
							htmlFor="email"
							className={classNames({ "p-error": !!errors.email })}
						>
							{t("profile.email")}
						</label>
					</span>
					{getFormErrorMessage("email")}
				</div>

				<div
					className="p-field p-col-12 p-md-6"
					style={{ margin: "28px 10px" }}
				>
					<span className="p-float-label">
						<Controller
							name="password"
							control={control}
							rules={{ required: t("profile.validations.password") }}
							render={({ field, fieldState }) => (
								<Password
									id={field.name}
									{...field}
									toggleMask
									className={classNames({
										"p-invalid": fieldState.invalid,
									})}
									header={passwordHeader}
									footer={passwordFooter}
								/>
							)}
						/>
						<label
							htmlFor="password"
							className={classNames({ "p-error": errors.password })}
						>
							{t("profile.password")}
						</label>
					</span>
					{getFormErrorMessage("password")}
				</div>

				<div
					className="login--screen--main--container--inputs--reset"
					onClick={openResetPasswordFunction}
				>
					{t("profile.changePassword")}
				</div>
				{openResetPassword && (
					<>
						<div
							className="p-field p-col-12 p-md-6"
							style={{ margin: "28px 10px" }}
						>
							<span className="p-float-label">
								<Controller
									name="newPassword"
									control={control}
									rules={{
										required: t("profile.validations.newPassword"),
										pattern: {
											value: /[A-Za-z0-9._%+-]$/i,
											message: "Tipear password",
										},
									}}
									render={({ field, fieldState }) => (
										<Password
											id={field.name}
											{...field}
											toggleMask
											className={classNames({
												"p-invalid": fieldState.invalid,
											})}
											header={passwordHeader}
											footer={passwordFooter}
										/>
									)}
								/>
								<label
									htmlFor="newPassword"
									className={classNames({ "p-error": errors.newPassword })}
								>
									{t("profile.newPassword")}
								</label>
							</span>
							{getFormErrorMessage("newPassword")}
						</div>

						<div
							className="p-field p-col-12 p-md-6"
							style={{ margin: "28px 10px" }}
						>
							<span className="p-float-label">
								<Controller
									name="confirmNewPassword"
									control={control}
									rules={{
										required: t("profile.validations.confirmPassword"),
										pattern: {
											value: /[A-Za-z0-9._%+-]$/i,
											message: "Tipear password",
										},
									}}
									render={({ field, fieldState }) => (
										<Password
											id={field.name}
											{...field}
											toggleMask
											className={classNames({
												"p-invalid": fieldState.invalid,
											})}
											header={passwordHeader}
											footer={passwordFooter}
										/>
									)}
								/>
								<label
									htmlFor="confirmNewPassword"
									className={classNames({
										"p-error": errors.confirmNewPassword,
									})}
								>
									{t("profile.confirmPassword")}
								</label>
							</span>
							{getFormErrorMessage("confirmNewPassword")}
						</div>
					</>
				)}

				<div
					className="p-field p-col-12 p-md-6"
					style={{ margin: "28px 10px" }}
				>
					<Button
						type="submit"
						label={t("profile.savechanges")}
						className="p-mt-2"
					/>
				</div>
			</form>
			<Messages ref={msgs1} />
		</div>
	);
};
