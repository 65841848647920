import React from "react";
import "./favProcess.css";

export const FavProcess = ({ process, onClickFavProcess, removeProcess }) => {
	//console.log("PROCESS: ", process);
	const { nombre, fuente_icono } = process;
	return (
		<div className="favProcess--main--container">
			<div
				className="favProcess--main--container--data"
				onClick={onClickFavProcess}
			>
				<i className={fuente_icono || `fas fa-list`} />
				<div> {nombre}</div>
			</div>
			<div
				className="favProcess--main--container--trash"
				onClick={removeProcess}
			>
				<i className={`fas fa-trash`} />
			</div>
		</div>
	);
};
