const getChildren = (id_nodo_padre, nodos, str_key) => {
  const hijos = nodos.filter(
    (oneNode) => oneNode.nodo_padre == id_nodo_padre && oneNode.nodo != 0
  );
  if (hijos.length == 0) { return null; }
  for (let index = 0; index < hijos.length; index++) {
    const nodoPadre = hijos[index];
    const idNodoPadre = nodoPadre.nodo;
    const keyPadre = str_key + "-" + index.toString();
    hijos[index].key = keyPadre;
    const childrens = getChildren(idNodoPadre, nodos, keyPadre);
    hijos[index].children = childrens;
    hijos[index].label = nodoPadre.nombre;
    if (isProcessNode(hijos[index])) {
      hijos[index].icon = hijos[index].fuente_icono;
    }
  }
  return hijos;
};

export const isProcessNode = (node) =>
  !node.children && node.tipo_proceso != "";

export const generateTree = (menuData) => {
  const nodes = menuData.nodes;
  if (nodes === undefined) {
    return null;
  }
  const godNode = nodes.find(
    (oneNode) => oneNode.nodo_padre == 0 && oneNode.nodo == 0
  );
  godNode.key = "0";
  const subNodes = getChildren(0, nodes, "0");
  godNode.children = subNodes;
  godNode.label = godNode.nombre;
  return godNode.children;
};

export const findNode = (list, e) => {
  return list.findIndex((node) => node.id == e.node.nodo);
};

export const createNodeTab = (element, componentData) => ({
  id: element.nodo,
  title: element.nombre,
  tooltip: element.proceso,
  procType: element.tipo_proceso,
  execType: element.tipo_ejecucion,
  iconClass: element.fuente_icono,
  target: (element.tipo_proceso==="A" && element.tipo_ejecucion==="F" ? "X": ""),
  panelComponent: (props) => {
    return componentData;
  },
});

function updateNodeFavoritesInTree(node, idNodo, newStatus) {
  if (node.nodo == idNodo) {
    node.guardadofavorito = newStatus;
  } else if (node.children != null) {
    for (let i = 0; i < node.children.length; i++) {
      updateNodeFavoritesInTree(node.children[i], idNodo, newStatus);
    }
  }
}

export const updateFavoritesMenuData = (menuData, node) => {
  try {
    //console.log(node.key);
    const arrKeys = node.key.split("-");
    const newStatus = node.guardadofavorito == "Si" ? "NO" : "Si";
    //agregado para obtener el submenu y pintar la estrella 09/05/2024
    var acumsubMenu = "";
    for (var i = 2; i < arrKeys.length - 1; i++) {
      var elemento = arrKeys[i];
      acumsubMenu += ".children["+elemento+"]";
    }
    //console.log(acumsubMenu);
    var subMenu = eval(`menuData.nodes[arrKeys[1]]${acumsubMenu}`); //var subMenu = menuData.nodes[arrKeys[0]];
    //fin de agregado
    updateNodeFavoritesInTree(subMenu, node.nodo, newStatus);
    const newNodes = menuData.nodes.map((oneNode) => {
      if (oneNode.nodo == subMenu.nodo) {
        return subMenu;
      }
      return oneNode;
    });
    const newMenuData = {
      nodes: newNodes,
      error: "0",
      message: "ok",
    };
    return newMenuData;
  } catch (error) {
    console.log("ERROR updateFavoritesMenuData: ", error);
    return menuData;
  }
};

export const updateFavoriteMenuList = (favorites, node, guardadofavorito) => {
  let result;
  if (guardadofavorito == "Si") {
    result = favorites.filter((x) => x.nodo != node.nodo);
  } else {
    result = [...favorites, node];
  }

  return result;
};
