import { types } from "../types/types";
import { saveLastUsed } from "../helpers/unloadEvent/unloadEvent";

export const emailPasswordRegister = (user, password) => {
  return async (dispatch) => {
    dispatch({ type: types.REQUEST_SESSION_LOADING });
    try {
      // const url = "/register";
      // const res = await fetch(url, {
      // 	method: "POST",
      // 	body: JSON.stringify({ user, password, company }),
      // 	headers: { "Content-Type": "application/json" },
      // });
      // console.log("RES: ", res);
      // if (res.status === 201) {
      // 	const response = await res.json();
      // 	dispatch({ type: types.REQUEST_SESSION_SUCCESS, payload:  });
      // 	// localStorage.setItem("token", response.token);
      // } else {
      // 	dispatch({ type: types.REQUEST_SESSION_FAILURE });
      // }
    } catch (error) {
      dispatch({ type: types.REQUEST_SESSION_FAILURE });
    }
  };
};

export const refreshUserSession = () => {
  return async (dispatch) => {
    dispatch({ type: types.REQUEST_SESSION_LOADING });

    const token = localStorage.getItem("token");
    setTimeout(() => {
      if (token) {
        dispatch({ type: types.REQUEST_SESSION_SUCCESS });
      } else {
        dispatch({ type: types.REQUEST_SESSION_FAILURE });
      }
    }, 500);
  };
};

export const startLogout = async () => {
  //acá primero debería preguntar si confirma salir
  saveLastUsed();
  const urlDomain = localStorage.getItem("reflogout");//await getDomain("logoutRedirect"); //cambio mp 30/11/2021
  localStorage.clear();
  sessionStorage.clear();  
  window.location.href = urlDomain;
};

export const logout = () => ({
  type: types.logout,
});
