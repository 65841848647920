export const initialStateUi = {
	activeMenu: null,
	/* ********************************* */
	loading: false,
	msgError: null,
	/* ********************************* */
	openSidebar: true,
	/* ********************************* */
	loadingNotifications: false,
	errorNotifications: false,
	notifications: null,
	/* ********************************* */
	loadingMenuData: false,
	errorMenuData: false,
	menuData: null,
	/* ********************************* */
	loadingIconsProcess: false,
	errorIconsProcess: false,
	iconsProcess: null,
	/* ********************************* */
	selectedNodes: { tabs: [] },
	/* ********************************* */
	hideTreeMenuData: false,
	/* ********************************* */
	openModalProfile: false,
	/* ********************************* */
	openModalNotification: false,
	/* ********************************* */
	loadingProcessesNode: false,
	errorProcessesNode: false,
	processesNode: null,
	/* ********************************* */
	loadingFavorites: false,
	errorFavorites: false,
	favorites: null,
	/* ********************************* */
	loadingLastUsed: false,
	errorLastUsed: false,
	lastUsed: null,
	/* ********************************* */
	newSelectedNodeTab: null,
};
