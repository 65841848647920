export const getUrlLogin = async (param) => {
	let url = "/config/routes.json";
	const res = await fetch(url, {
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	});
	const response = await res.json();
	const domain = response.domain2;
	const finalUrl = domain + response[param];
	return finalUrl;
};

export const getDomain = async (domain) => {
	let url = "/config/routes.json";
	const res = await fetch(url, {
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	});
	const response = await res.json();
	return response[domain];
};

export const getUrl = async (domain, route, param = "") => {
	let uri = "";
	let endpoint = "";

	const url = "/config/routes.json";
	const res = await fetch(url, {
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
		},
	});
	const response = await res.json();

	endpoint = response[route];

	uri = domain + endpoint + param;
	// console.log(uri);
	return uri;
};
