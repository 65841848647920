import React, { useState, useRef } from "react";
import { Base64 } from "js-base64";
import { Messages } from "primereact/messages";
import { Card } from "primereact/card";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
import { useForm, Controller } from "react-hook-form";
import { getUrl, getDomain } from "../../actions/getUrl";
import { LanguageImage } from "../../components/languageImage/languageImage";
import "./loginScreen.css";
import EspIcon from "../../assets/icons/sp-flag.png"; //agregado 09/11/2021
import EngIcon from "../../assets/icons/uk-flag.png"; //agregado 09/11/2021
import { Footer } from "../../components/footer/footer"; //agregadp 19/04/2024

export const LoginScreen = () => {
	const message = useRef(null);
	const { t, i18n } = useTranslation("global");
	const [firstLoginSuccess, setFirstLoginSuccess] = useState(false);
	const [compUser, setCompUser] = useState(null);
	const [appLanguage, setAppLanguage] = useState(i18n.language);
	const reflogout = window.location.href; //agregado mp 30/11/2021

	const defaultValues = {
		company: "",
		user: "",
	};

	const {
		control,
		formState: { errors },
		handleSubmit,
	} = useForm({ defaultValues });

	const getFormErrorMessage = (name) => {
		return (
			errors[name] && <small className="p-error">{errors[name].message}</small>
		);
	};

	const header = <div className="alchemy--logo--container" />;

	const redirectLogin2 = (
		company,
		user,
		url_fe_menu,
		connections,
		country,
		language,
	) => {
		const data = Base64.encode(
			JSON.stringify({
				company,
				user,
				url_fe_menu,
				connections,
				country,
				language,
				reflogout,
			}),
		);
		const uri = `${url_fe_menu}aLCHEMY/?session=${data}`;
		window.location.href = uri;
	};

	const addMessages = (sev, summ, det) => {
		message.current.show([
			{
				severity: sev,
				summary: `${summ} `,
				detail: ` ${det}`,
				sticky: null,
				life: 2500,
			},
		]);
	};

	const firstLogin = async (company, user) => {
		const param = `company=${company}&userid=${user}`;
		const domain = await getDomain("domain");
		const url = await getUrl(domain, "login1", param);
		const res = await fetch(url);
		if (res.status === 200) {
			const response = await res.json();
			const { success } = response;
			if (success === true) {
				const { connections, url_fe_menu, country } = response;
				if (connections.length > 0) {
					setCompUser({ company, user });
					redirectLogin2(
						company,
						user,
						url_fe_menu,
						connections,
						country,
						appLanguage,
					);
				} else {
					addMessages("error", "", t("login1.errors.noConnections"));
				}
			} else {
				addMessages("error", "", t("login1.errors.incorrectAccess"));
			}
		} else {
			addMessages("error", "", t("login1.errors.incorrectAccess"));
		}
	};

	const onSubmit = async ({ company, user }) => {
		firstLogin(company, user);
	};

	//autoFocus saco de user y lo paso a company mp 04/10/2021
	return (
		<div className="login--screen--main--container">
			<Card header={header} style={{ width: "400px" }}>
				<form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
					<div
						className="p-field p-col-12 p-md-6"
						style={{ margin: "28px 10px" }}
					>
						<span className="p-float-label p-input-icon-right">
							<i className="far fa-building" />
							<Controller
								name="company"
								control={control}
								rules={{
									required: "Company is required.",
									pattern: {
										value: /[A-Za-z0-9._%+-]$/i,
										message: "Ingresar Empresa",
									},
								}}
								render={({ field, fieldState }) => (
									<InputText
										disabled={firstLoginSuccess}
										id={field.company}
										{...field}
										autoFocus
										className={classNames({
											"p-invalid": fieldState.invalid,
										})}
									/>
								)}
							/>
							<label
								htmlFor="company"
								className={classNames({ "p-error": !!errors.company })}
							>
								{t("login1.company")}
							</label>
						</span>
						{getFormErrorMessage("company")}
					</div>

					<div
						className="p-field p-col-12 p-md-6"
						style={{ margin: "28px 10px" }}
					>
						<span className="p-float-label p-input-icon-right">
							<i className="far fa-user-circle" />
							<Controller
								name="user"
								control={control}
								rules={{ required: "User is required." }}
								render={({ field, fieldState }) => (
									<InputText
										disabled={firstLoginSuccess}
										id={field.user}
										{...field}
										className={classNames({
											"p-invalid": fieldState.invalid,
										})}
									/>
								)}
							/>
							<label
								htmlFor="user"
								className={classNames({ "p-error": errors.user })}
							>
								{t("login1.user")}
							</label>
						</span>
						{getFormErrorMessage("user")}
					</div>

					<div
						className="p-field p-col-12 p-md-6"
						style={{ margin: "28px 10px" }}
					>
						<Button
							type="submit"
							label={t("login1.signin")}
							className="p-button-info p-button-raised"
						/>
					</div>

					<div className="header--languages--container">
						<LanguageImage
							src={EspIcon}
							title="Es"
							onClick={() => {
								i18n.changeLanguage("es");
								setAppLanguage("es");
							}}
						/>
						<LanguageImage
							src={EngIcon}
							title="En"
							onClick={() => {
								i18n.changeLanguage("en");
								setAppLanguage("en");
							}}
						/>
					</div>
					<Messages ref={message} />
				</form>
			</Card>
			<Footer/>
		</div>	
	);
};
